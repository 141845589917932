import React, { useState } from 'react';

const NotificationBubble = ({ title, description }) => {
    const [visible, setVisible] = useState(true);

    const handleDismiss = () => {
        setVisible(false);
        localStorage.setItem(`dismissed-${description}`, true); // Prevent reappearance
    };

    // Check if the notification has been dismissed before
    React.useEffect(() => {
        if (localStorage.getItem(`dismissed-${description}`)) {
            setVisible(false);
        }
    }, [description]);

    if (!visible) return null;

    return (
        <div style={{
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            backgroundColor: '#2f3136',
            color: '#ffffff',
            padding: '16px',
            borderRadius: '12px',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
            zIndex: 1000,
            maxWidth: '400px',
            minWidth: '300px',
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            transition: 'background-color 0.2s'
        }}>
            <div style={{
                fontSize: '16px',
                fontWeight: 'bold',
                wordWrap: 'break-word',
                overflowWrap: 'break-word',
                wordBreak: 'break-word',
                whiteSpace: 'normal',
            }}>
                {title}
            </div>
            <div style={{
                fontSize: '14px',
                wordWrap: 'break-word',
                overflowWrap: 'break-word',
                wordBreak: 'break-word',
                whiteSpace: 'normal',
            }}>
                {description}
            </div>
            <button
                style={{
                    all: 'unset',
                    alignSelf: 'flex-end',
                    padding: '6px 12px',
                    backgroundColor: '#5865f2',
                    color: '#ffffff',
                    borderRadius: '8px',
                    cursor: 'pointer',
                    fontSize: '14px',
                    transition: 'background-color 0.2s',
                }}
                onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#4752c4'}
                onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#5865f2'}
                onClick={handleDismiss}
            >
                ОК
            </button>
        </div>
    );
};

export default NotificationBubble;
