import React, { useState } from 'react';
import './styles.css';

const Registration = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            alert("Пароли не совпадают");
            return;
        }
    
        const data = {
            uuid: null, // Вы можете сгенерировать UUID4 на клиенте, если нужно
            email,
            password,
            token: null // Это поле тоже можно оставить пустым, так как сервер сгенерирует токен
        };
    
        console.log(JSON.stringify(data));
    
        try {
            const response = await fetch("http://localhost:20010/auth/regin", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            });
    
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.detail || "Ошибка при регистрации");
            }
    
            const responseData = await response.json();
            alert("Успешная регистрация! Токен: " + responseData.token);
        } catch (error) {
            alert("Ошибка: " + error.message);
        }
    };
    

    return (
        <div className="registration-container">
            <form className="registration-form" onSubmit={handleSubmit}>
                <h2>Регистрация</h2>
                <input
                    className="input-field"
                    type="email"
                    placeholder="Электронная почта"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
                <input
                    className="input-field"
                    type="password"
                    placeholder="Пароль"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
                <input
                    className="input-field"
                    type="password"
                    placeholder="Подтвердите пароль"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                />
                <button className="submit-button" type="submit">Зарегистрироваться</button>
            </form>
        </div>
    );
};

export default Registration;
