import React, { useEffect, useState } from 'react';
import './Members.css';
import './Popup.css';

function getTypeLabel(type) {
    switch (type) {
        case 0:
            return 'User';
        case 1:
            return 'Bot';
        case 2:
            return 'Mod';
        case 3:
            return 'Staff';
        case 4:
            return 'System';
        default:
            return 'Unknown';
    }
}

function Members() {
    const [members, setMembers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedMember, setSelectedMember] = useState(null);
    const [isClosing, setIsClosing] = useState(false);

    useEffect(() => {
        // Запрашиваем данные с сервера
        fetch('https://p5.x5dfg.xyz:443/members')
            .then(response => response.json())
            .then(data => {
                const membersArray = Object.entries(data);
                setMembers(membersArray);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching members:', error);
                setLoading(false);
            });
    }, []);

    const handleMemberClick = (id) => {
        // Запрашиваем профиль участника
        fetch(`https://p5.x5dfg.xyz:443/${id}/profile`)
            .then(response => response.json())
            .then(data => {
                setSelectedMember(data);
            })
            .catch(error => {
                console.error('Error fetching member profile:', error);
            });
    };

    const handleClosePopup = () => {
        setIsClosing(true);
        setTimeout(() => {
            setSelectedMember(null);
            setIsClosing(false);
        }, 300);
    };

    return (
        <>
            {members.map(([id, member]) => (
                <div 
                    className="member" 
                    key={member.username} 
                    data-id={id}
                    onClick={() => handleMemberClick(id)} 
                >
                    <img 
                        src={member.avatar} 
                        alt={member.nick} 
                        title={member.username} 
                        className="member-avatar" 
                        loading="lazy"
                    />
                    <span className="member-nick">{member.nick}</span>
                    <span title={"Types:\n0-User\n1-Bot\n2-Mod\n3-Staff\n4-System\nOther-Unknow"} className="member-type">{getTypeLabel(member.type)}</span>
                </div>
            ))}
            {selectedMember && (
                <div className={`popup ${isClosing ? 'closing' : ''}`}>
                    <div className="header">
                        <img draggable="false" src={selectedMember.avatar} alt={selectedMember.nick} />
                        <span>
                            <h2 className='nick'>{selectedMember.nick}</h2>
                            <p className='username'>{selectedMember.username}</p>
                        </span>
                    </div>
                    <p>{selectedMember.about}</p>
                    <h3>Ссылки:</h3>
                    <div className="gridbox">
                        {selectedMember.links.map(link => (
                            <p key={link.address}>
                                <a className="links" rel="noreferrer" target='_blank' href={link.address}>
                                    {link.text}
                                </a>
                            </p>
                        ))}
                    </div>
                    <p>{selectedMember.badges.join(', ')}</p>
                    <p>День рождения: {selectedMember.birthday ? selectedMember.birthday : 'не указано'}</p>
                    <button onClick={handleClosePopup}>Закрыть</button>
                </div>
            )}
        </>
    );
}

export default Members;
