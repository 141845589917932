import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './newcss/root.css';
import Chat from './chat/Chat';
import MarketingPage from './marketing/MarketingPage';  // New marketing page component
import Check from './check/CheckBackend';
import Login from './auth/Login';
import Registration from './auth/Registration';

function NotFound() {
  const [alertClosed, setAlertClosed] = useState(false);

  useEffect(() => {
    alert('Page Not Found 404');
    setAlertClosed(true); 

    if (alertClosed) {
      window.location.href = '/';
    }
  }, [alertClosed]);

  return (
    <div style={{ textAlign: 'center', width: '100vw', height: '100vh' }}></div>
  );
};

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<MarketingPage />} />
        <Route path="/chat" element={<Chat />} />
        <Route path="/auth/login" element={<Login />} />
        <Route path="/auth/regin" element={<Registration />} />
        <Route path="/check" element={<Check />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  </React.StrictMode>
);
