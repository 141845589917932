import React, { useEffect, useState } from 'react';
import './navigation.css';

function NavBar() {
    const [guilds, setGuilds] = useState([]);
    const [error, setError] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalData, setModalData] = useState({ name: '', description: '', avatar: '', isNew: true, guildId: '' });
    const [contextMenu, setContextMenu] = useState({ visible: false, x: 0, y: 0, guild: null });
    const accessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiNjNiYmNlNTgtODM1MS00MDU3LWE4OTYtMzJkODY2NWU2MGM4IiwiaWF0IjoxNzI5Nzk0NDU1fQ.UEzl8MMCPx4-seVk3U7Mx3g7wBdLeNMKA-xUrrfbF8c'; // Замените на ваш токен

    // Функция для получения гильдий
    async function fetchGuilds() {
        try {
            const response = await fetch(`https://p5.x5dfg.xyz/guild/get_all?access_token=${accessToken}`);
            if (!response.ok) {
                const errorData = await response.json();
                setError(errorData.detail || 'Неизвестная ошибка');
                setGuilds([]);
                return;
            }
            const data = await response.json();
            if (Array.isArray(data)) {
                setGuilds(data);
            } else {
                setError('Полученные данные не являются массивом');
                setGuilds([]);
            }
        } catch (error) {
            console.error('Ошибка при получении гильдий:', error);
            setError('Ошибка при получении гильдий');
        }
    };

    // Функция для создания или обновления гильдии
    async function saveGuild() {
        const url = modalData.isNew ? 'https://p5.x5dfg.xyz/guild/create' : `https://p5.x5dfg.xyz/guild/put/${modalData.guildId}`;
        const method = modalData.isNew ? 'POST' : 'PUT';
    
        const requestBody = {
            id: modalData.guildId || '', // Добавляем id только для обновления
            type: 0, // Здесь установите корректный тип, если это требуется
            name: modalData.name,
            description: modalData.description,
            avatar: modalData.avatar,
            ownerId: '1', // Убедитесь, что у вас есть корректное значение ownerId
            channelIds: [] // Здесь можно передать массив с channelIds, если это требуется
        };
    
        try {
            const response = await fetch(url, {
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
                body: JSON.stringify(requestBody),
            });
            if (response.ok) {
                fetchGuilds();
                setIsModalOpen(false);
            } else {
                const errorData = await response.json();
                setError(errorData.detail || 'Неизвестная ошибка');
            }
        } catch (error) {
            console.error('Ошибка при сохранении гильдии:', error);
            setError('Ошибка при сохранении гильдии');
        }
    }
    

    // Функция для удаления гильдии
    async function deleteGuild(id) {
        try {
            const response = await fetch(`https://p5.x5dfg.xyz/guild/delete/${id}`, {
                method: 'DELETE',
                headers: { 
                    'Authorization': `Bearer ${accessToken}` 
                },
            });
            if (response.ok) {
                fetchGuilds();
            } else {
                const errorData = await response.json();
                setError(errorData.detail || 'Неизвестная ошибка');
            }
        } catch (error) {
            console.error('Ошибка при удалении гильдии:', error);
            setError('Ошибка при удалении гильдии');
        }
    };

    // Открытие модального окна для создания новой гильдии
    const openCreateModal = () => {
        setModalData({ name: '', description: '', avatar: '', isNew: true, guildId: '' });
        setIsModalOpen(true);
    };

    // Открытие модального окна для редактирования гильдии
    const openEditModal = (guild) => {
        setModalData({ name: guild.name, description: guild.description, avatar: guild.avatar, isNew: false, guildId: guild.id });
        setIsModalOpen(true);
    };

    const handleContextMenu = (e, guild) => {
        e.preventDefault();
        setContextMenu({
            visible: true,
            x: e.clientX,
            y: e.clientY,
            guild: guild
        });
    };

    const handleCloseContextMenu = () => {
        setContextMenu({ ...contextMenu, visible: false });
    };

    useEffect(() => {
        fetchGuilds();
        // Закрытие контекстного меню при клике вне его
        const closeMenuOnClick = () => {
            if (contextMenu.visible) {
                handleCloseContextMenu();
            }
        };
        document.addEventListener('click', closeMenuOnClick);
        return () => {
            document.removeEventListener('click', closeMenuOnClick);
        };
    }, [contextMenu.visible]);

    return (
        <aside className='nav'>
            <button className='create-btn' onClick={openCreateModal}>+</button>
            {error && <p style={{ color: 'red' }}>{typeof error === 'string' ? error : 'Ошибка'}</p>} 
            <ul>
                {guilds.map((guild) => (
                    <li 
                        key={guild.id} 
                        onContextMenu={(e) => handleContextMenu(e, guild)}
                    >
                        <img 
                            className='guild-avatar' 
                            onClick={() => window.location.href = `/${guild.id}`} 
                            title={guild.name} 
                            src={guild.avatar}
                            loading="lazy"
                        />
                    </li>
                ))}
            </ul>

            {/* Контекстное меню */}
            {contextMenu.visible && (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                width: '160px',
                position: 'absolute',
                top: contextMenu.y,
                left: contextMenu.x,
                backgroundColor: '#2f3136',
                zIndex: 1000,
                borderRadius: '8px',
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
                overflow: 'hidden',
                padding: '4px 0',
            }}>
                <button style={{
                    all: 'unset',
                    display: 'block',
                    width: '100%',
                    padding: '8px 12px',
                    textAlign: 'left',
                    color: '#ffffff',
                    fontSize: '14px',
                    cursor: 'pointer',
                    transition: 'background-color 0.2s',
                }} 
                onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#3e4047'}
                onMouseOut={(e) => e.currentTarget.style.backgroundColor = 'transparent'}
                onClick={() => {
                    openEditModal(contextMenu.guild);
                    handleCloseContextMenu();
                }}>
                    Редактировать
                </button>
                
                <button style={{
                    all: 'unset',
                    display: 'block',
                    width: '100%',
                    padding: '8px 12px',
                    textAlign: 'left',
                    color: '#f04747',
                    fontSize: '14px',
                    cursor: 'pointer',
                    transition: 'background-color 0.2s',
                }} 
                onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#3e4047'}
                onMouseOut={(e) => e.currentTarget.style.backgroundColor = 'transparent'}
                onClick={() => {
                    deleteGuild(contextMenu.guild.id);
                    handleCloseContextMenu();
                }}>
                    Удалить яички
                </button>
            </div>
        )}


            {/* Модальное окно */}
            {isModalOpen && (
                <div className='popup'>
                    <div className='g'>
                        <h3>{modalData.isNew ? 'Создать гильдию' : 'Редактировать гильдию'}</h3>
                        <input
                            type="text"
                            maxLength="50"
                            placeholder="Название"
                            value={modalData.name}
                            onChange={(e) => setModalData({ ...modalData, name: e.target.value })}
                            required
                        />
                        <br/>
                        <input
                            type="text"
                            maxLength="350"
                            placeholder="Описание"
                            value={modalData.description}
                            onChange={(e) => setModalData({ ...modalData, description: e.target.value })}
                        /><br/>
                        <input
                            type="text"
                            placeholder="URL аватарки"
                            value={modalData.avatar}
                            onChange={(e) => setModalData({ ...modalData, avatar: e.target.value })}
                        /><br/>
                        <button onClick={saveGuild}>Сохранить</button>
                        <button onClick={() => setIsModalOpen(false)}>Закрыть</button>
                    </div>
                </div>
            )}
        </aside>
    );
};

export default NavBar;
