import React from 'react';
import './MarketingPage.css';  // Подключаем стили

const ProjectName = 'Project 5';

const MarketingPage = () => {
  return (
    <div className="marketing-container">
      <header className="hero">
        <div className="hero-content">
          <h1 className="title">Добро пожаловать в {ProjectName}</h1>
          <p className="subtitle">Лучшее решение для управления проектами с простым интерфейсом и мощными функциями.</p>
          <button onClick={() => window.location.href = '/auth/login'} className="cta-button">Попробовать бесплатно</button>
        </div>
      </header>

      <section className="features">
        <h2 className="section-title">Почему стоит выбрать {ProjectName}?</h2>
        <div className="feature-list">
          <div className="feature-item">
            <h3>Мгновенные обновления</h3>
            <p>Обновляйтесь в реальном времени и следите за процессом работы без задержек.</p>
          </div>
          <div className="feature-item">
            <h3>Удобство в использовании</h3>
            <p>Простой и интуитивно понятный интерфейс, который понравится даже новичкам.</p>
          </div>
          <div className="feature-item">
            <h3>Мощная аналитика</h3>
            <p>Полный контроль над данными и анализ результатов с помощью подробных отчетов.</p>
          </div>
          <div className="feature-item">
            <h3>Возможности для разработчиков</h3>
            <p>Используйте мощный API для интеграции {ProjectName} с вашими инструментами и сервисами.</p>
          </div>
          <div className="feature-item">
            <h3>Совместная работа</h3>
            <p>Работайте в одной команде, обменивайтесь идеями и файлами в реальном времени.</p>
          </div>
          <div className="feature-item">
            <h3>Высокий уровень безопасности</h3>
            <p>Ваши данные надёжно защищены с использованием современных стандартов шифрования.</p>
          </div>
        </div>
      </section>

      <section className="testimonial">
        <h2 className="section-title">Что говорят пользователи</h2>
        <p>«{ProjectName} помог нам улучшить командную работу и ускорить выполнение задач.» — Анна, менеджер проектов</p>
      </section>

      <footer className="footer">
        <p>&copy; {new Date().getFullYear()} {ProjectName}. Все права защищены.</p>
      </footer>
    </div>
  );
};

export default MarketingPage;
