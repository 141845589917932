import React, { memo } from 'react';
import ReactMarkdown from 'react-markdown';

// Функция для отображения сообщения
const Message = memo(({ msg, handleDelete, getTypeLabel }) => {
  return (
    <div id={msg.id} data-id={msg.id} className="chat-message">
      {msg.user && (
        <img
          className="avatar"
          draggable="false"
          src={msg.user.avatar}
          title={msg.user.username}
          alt={`Avatar of ${msg.user.username}`}
          loading="lazy"
        />
      )}
      <div className="message-content">
        <div className="message-header">
          <span className="username">
            {msg.user ? `${msg.user.nick} / ${getTypeLabel(msg.user.type)}` : 'Unknown user'}
          </span>
          <span className="timestamp">{new Date(msg.timestamp * 1000).toLocaleString()}</span>
          <button className="delete-button" onClick={() => handleDelete(msg.id)}>
            Удалить
          </button>
          <span className="client-label" style={{ marginLeft: '8px', fontSize: '0.9em', color: '#666' }}>
            {msg.client === 'prod' ? 'Client: Production' : 'Client: Dev-Core'}
          </span>
        </div>
        <div className="message-text">
          <ReactMarkdown>{msg.content}</ReactMarkdown>
        </div>
        {msg.images && msg.images.length > 0 && (
          <div className="message-images">
            {msg.images.map((image, idx) => (
              <img key={idx} src={image} alt={`${idx}`} loading="lazy" className="message-image" />
            ))}
          </div>
        )}
      </div>
    </div>
  );
});

export default Message;